import {AfterViewInit, Component, HostBinding, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-site-data-protection',
    templateUrl: './site-data-protection.component.html',
    styleUrls: ['./site-data-protection.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SiteDataProtectionComponent implements AfterViewInit {

    @HostBinding('class') class = 'site-data-protection';

    ngAfterViewInit() {
        window.scroll(0, 0);
    }

}
