import {AfterViewInit, Component, HostBinding, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-site-imprint',
    templateUrl: './site-imprint.component.html',
    styleUrls: ['./site-imprint.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SiteImprintComponent implements AfterViewInit {

    @HostBinding('class') class = 'site-imprint';

    ngAfterViewInit() {
        window.scroll(0, 0);
    }

}
