import {AfterViewInit, Component} from '@angular/core';

@Component({
    selector: 'app-site-home',
    templateUrl: './site-home.component.html',
    styleUrls: ['./site-home.component.scss']
})
export class SiteHomeComponent implements AfterViewInit {

    ngAfterViewInit() {
        window.scroll(0, 0);
    }

}
