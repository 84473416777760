export class Helper {

    static registerIcons(matIconRegistry, domSanitizer) {

        const matIconPath = 'assets/icons/material/';
        const matIconType = 'round';
        const icons = [
            'keyboard_arrow_up'
        ];

        for (const icon of icons) {
            matIconRegistry.addSvgIcon(icon, domSanitizer.bypassSecurityTrustResourceUrl(matIconPath + matIconType + '-' + icon + '.svg'));
        }

    }

}
