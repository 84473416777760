import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './components/header/header.component';
import {
    MatButtonModule, MatCardModule, MatDialogModule, MatExpansionModule, MatIconModule,
    MatListModule
} from "@angular/material";
import {TitleComponent} from './components/title/title.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {LanguageSwitcherComponent} from './components/language-switcher/language-switcher.component';
import {Mark6DropDownModule} from "@devmonkeys/ngx-mark6";
import {CoachingComponent} from "./components/coaching/coaching.component";
import {AboutMeComponent} from './components/about-me/about-me.component';
import {FaqComponent} from './components/faq/faq.component';
import {TeachingUnitsComponent} from './components/teaching-units/teaching-units.component';
import {FooterComponent} from './components/footer/footer.component';
import {SiteHomeComponent} from './sites/site-home/site-home.component';
import {SiteImprintComponent} from './sites/site-imprint/site-imprint.component';
import {SiteDataProtectionComponent} from './sites/site-data-protection/site-data-protection.component';
import {HeaderSubPageComponent} from "./components/header-sub-page/header-sub-page.component";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HeaderSubPageComponent,
        CoachingComponent,
        TitleComponent,
        LanguageSwitcherComponent,
        AboutMeComponent,
        FaqComponent,
        TeachingUnitsComponent,
        FooterComponent,
        SiteHomeComponent,
        SiteImprintComponent,
        SiteDataProtectionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatCardModule,
        MatDialogModule,
        MatExpansionModule,
        Mark6DropDownModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
