import {Component, HostBinding, ViewEncapsulation} from '@angular/core';


@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class FaqComponent {

    @HostBinding('id') id = 'faq';

    faqs = [
        {
            title: 'component.faq.1.title',
            content: 'component.faq.1.content'
        },
        {
            title: 'component.faq.2.title',
            content: 'component.faq.2.content'
        },
        {
            title: 'component.faq.3.title',
            content: 'component.faq.3.content'
        },
        {
            title: 'component.faq.4.title',
            content: 'component.faq.4.content'
        },
        {
            title: 'component.faq.5.title',
            content: 'component.faq.5.content'
        },
        {
            title: 'component.faq.6.title',
            content: 'component.faq.6.content'
        }
    ]

}
