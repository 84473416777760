import {Component} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    public menus = [
        {title: 'menu.coaching', url: '#coaching'},
        {title: 'menu.about_me', url: '#about-me'},
        {title: 'menu.faq', url: '#faq'},
        {title: 'menu.teaching_units', url: '#teaching-units'}
    ]

}
