import {Component, HostBinding, ViewEncapsulation} from '@angular/core';


@Component({
    selector: 'app-about-me',
    templateUrl: './about-me.component.html',
    styleUrls: ['./about-me.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class AboutMeComponent {

    @HostBinding('id') id = 'about-me';

}
