import {Component, HostBinding, ViewEncapsulation} from '@angular/core';


@Component({
    selector: 'app-teaching-units',
    templateUrl: './teaching-units.component.html',
    styleUrls: ['./teaching-units.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TeachingUnitsComponent {

    @HostBinding('id') id = 'teaching-units';

}
