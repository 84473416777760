import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SiteHomeComponent} from "./sites/site-home/site-home.component";
import {SiteImprintComponent} from "./sites/site-imprint/site-imprint.component";
import {SiteDataProtectionComponent} from "./sites/site-data-protection/site-data-protection.component";


const routes: Routes = [
    { path: '', component: SiteHomeComponent },
    { path: 'imprint', component: SiteImprintComponent },
    { path: 'data-protection', component: SiteDataProtectionComponent },
    { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
