import {Component, HostListener} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Helper} from "./app.helper";
import {MatIconRegistry} from "@angular/material";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


export class AppComponent {

    public showScrollToTop = false;

    @HostListener('window:scroll', ['$event'])
    doSomething(event) {
        if (window.pageYOffset > 100) {
            this.showScrollToTop = true;
        } if (window.pageYOffset < 100) {
            this.showScrollToTop = false;
        }
    }

    constructor(private domSanitizer: DomSanitizer,
                private matIconRegistry: MatIconRegistry,
                private translate: TranslateService) {

        Helper.registerIcons(this.matIconRegistry, this.domSanitizer);

        if (translate.getBrowserLang() === 'en' || 'de' || 'pl' || 'ro') {
            translate.setDefaultLang(translate.getBrowserLang());
        } else {
            translate.setDefaultLang('en');
        }

    }

}
