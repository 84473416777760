import {Component, HostBinding, ViewEncapsulation} from '@angular/core';


@Component({
    selector: 'app-coaching',
    templateUrl: './coaching.component.html',
    styleUrls: ['./coaching.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class CoachingComponent {

    @HostBinding('id') id = 'coaching';

}
