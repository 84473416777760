import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {MatDialog, MatIconRegistry} from "@angular/material";
import {DomSanitizer} from "@angular/platform-browser";
import {MediaMatcher} from "@angular/cdk/layout";
import {Mark6DropDownService} from "@devmonkeys/ngx-mark6";


@Component({
    selector: 'app-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})


export class LanguageSwitcherComponent implements OnInit, OnDestroy {

    public languages = [
        {
            value: 'en',
            title: 'languages.en'
        },
        {
            value: 'de',
            title: 'languages.de'
        },
        {
            value: 'es',
            title: 'languages.es'
        },
        {
            value: 'pl',
            title: 'languages.pl'
        },
        {
            value: 'ro',
            title: 'languages.ro'
        }
    ];
    public currentLanguage: string;

    public mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    constructor(private iconRegistry: MatIconRegistry,
                private sanitizer: DomSanitizer,
                private translate: TranslateService,
                changeDetectorRef: ChangeDetectorRef,
                media: MediaMatcher,
                public dialog: MatDialog,
                private dropDown: Mark6DropDownService) {

        this.currentLanguage = translate.getBrowserLang();

        const flagIconPath = 'assets/icons/flags/';
        const flags = ['en', 'de', 'es', 'pl', 'ro'];

        for (const flag of flags) {
            iconRegistry.addSvgIcon(flag, sanitizer.bypassSecurityTrustResourceUrl(flagIconPath + flag + '.svg'));
        }

        this.mobileQuery = media.matchMedia('(min-width: 1280px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    closeDropDown() {
        this.dropDown.close(null);
    }

    switchLanguage(language) {
        this.translate.use(language);
    }

    switchLanguageDialog(templateRef) {
        this.dialog.open(templateRef, {
            width: '360px',
        });
    }

    closeSwitchLanguageDialog() {
        this.dialog.closeAll();
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currentLanguage = event.lang;
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

}
